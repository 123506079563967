import React from "react";
import './styles.scss'
import {StaticImage} from "gatsby-plugin-image";
import {LocaleType} from "../../../interfaces/Locale";

export default function Tests({locale}:{locale:LocaleType}) {
    return <div className="test-container">
        <div className="head">

            <div className="title">
                {locale['Home.Tests.title']}
            </div>
            <div className="underline"/>
            <div className="text">
                {locale['Home.Tests.desc1']}
                <br/><br/>
                {locale['Home.Tests.desc2']}
            </div>
            <div className="spiral">
                <StaticImage placeholder="blurred" src="../../../images/spiral.png" alt="spiral"/>
            </div>
        </div>
        <div className="elements">
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.test1.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.test1.desc1']}
                        <br/>
                        <br/>
                        {locale['Home.Tests.test1.desc2']}
                    </div>
                    </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/1.png" alt="test1"/>
                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.test2.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.test2.desc1']}
                        <br/><br/>
                        {locale['Home.Tests.test2.desc2']}
                    </div>
                    </div>

                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/2.png" alt="test1"/>

                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.test3.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.test3.desc1']}<br/>
                        <br/>
                        {locale['Home.Tests.test3.desc2']}
                    </div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/3.png" alt="test1"/>

                </div>
            </div>
            <div className="element">

                <div className="content">
                    <div className="title">{locale['Home.Tests.test4.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.test4.desc1']}
                        <br/>
                        <br/>
                        {locale['Home.Tests.test4.desc2']}
                    </div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/4.png" alt="test1"/>

                </div>

            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.test5.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.test5.desc1']}
                        <br/>
                        <br/>
                        {locale['Home.Tests.test5.desc2']}
                    </div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/5.png" alt="test1"/>

                </div>
            </div>

        </div>
        <div className="pattern">
            <StaticImage placeholder="blurred" src="../../../images/dot_pattern3.png" alt="pattern"/>
        </div>
    </div>
}